




import { Component, Vue } from 'vue-property-decorator'
import VisitDetail from './components/VisitDetail.vue'

@Component({
  name: 'CreateVisit',
  components: {
    VisitDetail
  }
})
export default class extends Vue {}
